import {
  Anchor,
  Box,
  Container,
  Divider,
  FlexBox,
  Heading,
  Rhythm,
  Text,
} from '@trmediaab/zebra';

import ExternalLink from '~/components/ExternalLink';
import { FAQ_LINK, MY_PAGES_LINK } from '~/constants/navLinks';

const CustomerServiceSection = () => (
  <Box as="section" bg="greys.0" py={[4, null, null, '5,5']}>
    <Container margin="auto">
      <Heading.h1 as="h2" mb={['20px', null, null, '55px']}>
        Kundtjänst
      </Heading.h1>

      <Rhythm
        display="grid"
        sx={{
          gridTemplateColumns: ['1fr', null, '1fr 1fr', '1fr 1fr 1fr'],
          rowGap: ['3', null, '3,5', '4,5'],
          columnGap: ['3', null, '3,5', 4],
        }}
        between={[3, null, null, 0]}
      >
        <Rhythm between={[4, null, null, 5]}>
          <FlexBox flexDirection="column">
            <Anchor
              href="tel:+46844688600"
              variant="matchText"
              textDecoration="none"
              fontSize="20px"
              fontWeight="600"
              lineHeight={[null, null, null, '1']}
            >
              08-44 68 86 00
            </Anchor>
            <Anchor
              href="mailto:info@trmedia.se"
              fontWeight="600"
              lineHeight={[null, null, null, '2']}
            >
              info@trmedia.se
            </Anchor>
          </FlexBox>
          <Text.p>
            Travrondens kundtjänst för prenumerations- och fakturafragor.
          </Text.p>
          <Text.p>Vardagar kl 8.00-18.00 samt lördagar 09.00-16.00.</Text.p>
        </Rhythm>

        <Rhythm between={[5, null, null, 6]}>
          <Rhythm between={[4, null, null, 4]}>
            <Heading.h3>Mina sidor</Heading.h3>
            <Text.p mb={['5px', null, null, '20px']}>
              På Mina sidor kan du hantera de allra flesta ärenden själv.
            </Text.p>
            <ExternalLink href={MY_PAGES_LINK.href} textDecoration="none" icon>
              {MY_PAGES_LINK.name}
            </ExternalLink>
          </Rhythm>

          <Divider />

          <Rhythm between={[4, null, null, 4]}>
            <Heading.h3>Vanliga frågor och svar</Heading.h3>
            <Text.p mb={['5px', null, null, '20px']}>
              Här hittar du svar på våra vanligaste frågor.
            </Text.p>
            <ExternalLink href={FAQ_LINK.href} textDecoration="none" icon>
              {FAQ_LINK.name}
            </ExternalLink>
          </Rhythm>
        </Rhythm>

        <Box>
          <Heading.h3 mb="15px">Har du andra produktfrågor?</Heading.h3>
          <Text.p>
            Spelvärde,{' '}
            <Anchor href="mailto:support@spelvarde.se">
              support@spelvarde.se
            </Anchor>
          </Text.p>
          <Text.p>
            Jokersystemet,{' '}
            <Anchor href="mailto:support@jokersystemet.se">
              support@jokersystemet.se
            </Anchor>
          </Text.p>
          <Text.p>
            Travfakta,{' '}
            <Anchor href="mailto:info@trmedia.se">info@trmedia.se</Anchor>
          </Text.p>

          <Text.p>
            Köpandel,{' '}
            <Anchor href="mailto:info@trmedia.se">info@trmedia.se</Anchor>
          </Text.p>
        </Box>
      </Rhythm>
    </Container>
  </Box>
);

export default CustomerServiceSection;
