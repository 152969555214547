import {
  Anchor,
  Box,
  Container,
  FlexBox,
  Heading,
  Rhythm,
  Text,
} from '@trmediaab/zebra';
import Link from 'next/link';

import CloudinaryImage from '~/components/CloudinaryImage';
import { useGetContentQuery } from '~/services/trais';

const queryArgs = 'om-oss';

const AboutUsSection = () => {
  const { data } = useGetContentQuery(queryArgs);

  const {
    teaser,
    teaser_headline: teaserHeadline,
    teaser_image: teaserImage,
  } = data || {};

  return (
    <Box as="section" backgroundColor="#f1f1f1" py={[4, null, null, '5,5']}>
      <Container margin="auto">
        <Box
          display="grid"
          sx={{
            gridTemplateColumns: ['1fr', null, '1fr 1fr', null],
            rowGap: ['4', null, '3,5', '4,5'],
            columnGap: ['3', null, '3,5', '4'],
          }}
        >
          <Rhythm between={[3, null, null, 3]}>
            <Heading.h1 as="h2">{teaserHeadline}</Heading.h1>
            <Text.p>{teaser}</Text.p>

            <Box>
              <Anchor as={Link} href="/om-oss" fontWeight="500">
                Mer om TR Media
              </Anchor>
            </Box>
          </Rhythm>

          <FlexBox justifyContent="flex-end">
            <Box flex={['0 0 100%', null, null, '0 0 500px']}>
              <CloudinaryImage
                image={teaserImage}
                ratio={[175, 100]}
                width={500}
                layout="responsive"
                fallback
              />
            </Box>
          </FlexBox>
        </Box>
      </Container>
    </Box>
  );
};

export default AboutUsSection;
