import { Box, Position } from '@trmediaab/zebra';

// Video embed snippets taken directly from Vimeo, but with some added iframe params
// Note that it's very important to set muted=1 in combination with autoplay=1
// since some browser will not allow autoplay unless the video is muted.
// Will be inserted directly as HTML for simplicity. Script taken out of snippet
// as playback works fine without it.
const rawVimeoHTMLDesktop = `
  <div style="padding:56.25% 0 0 0;position:relative;">
    <iframe
      src="https://player.vimeo.com/video/723356199?h=31ce18c6bc&amp;badge=0&amp;autopause=0&amp;autoplay=1&amp;loop=1&amp;controls=0&amp;muted=1&amp;player_id=0&amp;app_id=58479"
      frameborder="0"
      allow="autoplay; picture-in-picture"
      style="position:absolute;top:0;left:0;width:100%;height:100%;"
    ></iframe>
  </div>
`;
const rawVimeoHTMLMobile = `
  <div style="padding:156.15% 0 0 0;position:relative;">
    <iframe
      src="https://player.vimeo.com/video/723355041?h=078e57a2f6&amp;badge=0&amp;autopause=0&amp;autoplay=1&amp;loop=1&amp;controls=0&amp;muted=1&amp;player_id=0&amp;app_id=58479"
      frameborder="0"
      allow="autoplay; picture-in-picture"
      style="position:absolute;top:0;left:0;width:100%;height:100%;"
    ></iframe>
  </div>
`;

// Note: 1px negative margin-top due to observed white pixel line above Hero
// on iPhone. Possibly related to scaling/rounding issues on retina display.
const HeroSection = () => (
  <Position.relative marginTop="-1px">
    <Box display={{ _: 'none', sm: 'block' }}>
      <div dangerouslySetInnerHTML={{ __html: rawVimeoHTMLDesktop }} />
      <Position.absolute
        display={{ _: 'none', lg: 'block' }}
        top="0"
        left="0"
        width="100%"
        height="27%"
        sx={{ background: 'linear-gradient(rgba(0,0,0,0.4), transparent)' }}
      />
    </Box>
    <Box
      display={{ _: 'block', sm: 'none' }}
      dangerouslySetInnerHTML={{ __html: rawVimeoHTMLMobile }}
    />
  </Position.relative>
);

export default HeroSection;
