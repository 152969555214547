import { Box, Container, FlexBox, Heading, Text } from '@trmediaab/zebra';

import ArticleList from '~/components/ArticleList';
import type { TeaserListQueryArgs } from '~/services/trais';
import { useGetContentListQuery, useGetContentQuery } from '~/services/trais';

const sectionQueryArgs = 'aktuellt';

interface SectionProps {
  queryArgs: TeaserListQueryArgs;
  grey?: boolean;
}

const NewsSection = (props: SectionProps) => {
  const newsQueryResult = useGetContentListQuery(props.queryArgs);
  const { data: articles } = newsQueryResult;
  const { data: sectionData } = useGetContentQuery(sectionQueryArgs);
  const { teaser, teaser_headline: teaserHeadline } = sectionData || {};

  const background = props.grey ? 'greys.0' : undefined;
  const tileColor = props.grey ? 'background.primary' : 'greys.0';

  return (
    <Box padding={['30px 0', null, null, '108px 0 110px']} bg={background}>
      <Container margin="auto">
        <FlexBox flexDirection={['column', null, null, 'row']}>
          <Box
            flex={['1 0 initial', null, null, '1 0 270px']}
            paddingRight="32px"
            mb={['40px', null, null, null]}
          >
            <Heading.h1 as="h2" mb="15px">
              {teaserHeadline}
            </Heading.h1>
            <Text.p>{teaser}</Text.p>
          </Box>
          <Box>
            <ArticleList articles={articles} tileColor={tileColor} />
          </Box>
        </FlexBox>
      </Container>
    </Box>
  );
};

export default NewsSection;
