import { Box } from '@trmediaab/zebra';
import { brands } from '@trmediaab/zebra-utils';
import { useTheme } from 'styled-components';

import BrandTile from './BrandTile';

const brandsToRender = brands
  .getBrands()
  .filter(brand => brand.tileId && brand.iconId);

interface BrandGridProps {
  scrollableOnMobile?: boolean;
}
const BrandGrid = (props: BrandGridProps) => {
  const {
    space: { gutters },
  } = useTheme();
  let gridTemplateColumns: Nullable<string>[] = [
    '1fr',
    '1fr 1fr',
    '1fr 1fr 1fr',
    '1fr 1fr 1fr 1fr',
  ];
  let paddingBottom: number | Nullable<number>[] = 0;
  let mx: number | Nullable<number>[] = 0;
  // Just pl since padding-right doesn't work anyway in order to expand
  // scrollable container. Solved through paddingRightItem for now.
  let pl: number | Nullable<number>[] = 0;
  let paddingRightItem = null;
  if (props.scrollableOnMobile) {
    gridTemplateColumns = [
      `repeat(${brandsToRender.length}, 265px) 1px`, // last 1px for paddingRightItem
      null,
      null,
      '1fr 1fr 1fr 1fr',
    ];
    paddingBottom = [4, null, null, 0]; // Gives scrollbar some space
    // @ts-expect-error TODO Property 'sm' / 'md' does not exist on type 'SpaceProperty | ObjectOrArray<SpaceProperty, string | number | symbol>'.
    mx = [gutters.sm * -1, gutters.md * -1, gutters.lg * -1, 0];
    // @ts-expect-error TODO Property 'sm' / 'md' does not exist on type 'SpaceProperty | ObjectOrArray<SpaceProperty, string | number | symbol>'.
    pl = [gutters.sm, gutters.md, gutters.lg, 0];
    paddingRightItem = <Box display={['block', null, null, 'none']} />;
  }
  return (
    <Box overflowX="auto" pb={paddingBottom} mx={mx} pl={pl}>
      <Box
        display="grid"
        sx={{
          gridTemplateColumns,
          rowGap: [3, null, '3,5', '4,5'],
          columnGap: [3, null, '3,5', 4],
        }}
      >
        {brandsToRender.map(brand => (
          <BrandTile key={brand.name} {...brand} />
        ))}
        {paddingRightItem}
      </Box>
    </Box>
  );
};

export default BrandGrid;
