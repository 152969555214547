import {
  Box,
  Button,
  Container,
  Heading,
  Rhythm,
  Text,
} from '@trmediaab/zebra';
import Link from 'next/link';

import CloudinaryImage from '~/components/CloudinaryImage';
import SiteLayout from '~/components/SiteLayout';
import { wrapper } from '~/main/store';
import AboutUsSection from '~/sections/AboutUsSection';
import BrandsSection from '~/sections/BrandsSection';
import CustomerServiceSection from '~/sections/CustomerServiceSection';
import HeroSection from '~/sections/HeroSection';
import NewsSection from '~/sections/NewsSection';
import type { TeaserListQueryArgs } from '~/services/trais';
import {
  getContent,
  getContentList,
  getRunningQueriesThunk,
  useGetContentQuery,
} from '~/services/trais';

const queryArgsAboutUs = 'om-oss';
const queryArgsNews = 'aktuellt';
const queryArgsBrands = 'varumarken';
const queryArgsAdvertise = 'annonsera';
const queryArgsTrStudio = 'tr-studio';

interface PageProps {
  queryArgs: TeaserListQueryArgs;
}

const Home = (props: PageProps) => {
  const advertiseQueryResult = useGetContentQuery(queryArgsAdvertise);
  const { data: advertiseData } = advertiseQueryResult;

  const trStudioQueryResult = useGetContentQuery(queryArgsTrStudio);
  const { data: trStudioData } = trStudioQueryResult;

  return (
    <>
      <HeroSection />

      <AboutUsSection />

      <NewsSection {...props} />

      <BrandsSection />

      <Box as="section" padding={['20px 0 35px', null, null, '54px 0 110px']}>
        <Container margin="auto">
          <Box
            display="grid"
            sx={{
              gridTemplateColumns: ['1fr', null, '1fr 1fr', null],
              rowGap: ['4', null, '3,5', '4,5'],
              columnGap: ['3', null, '3,5', '4'],
            }}
          >
            <Rhythm between={[3, null, null, '1,5']}>
              <CloudinaryImage
                image={advertiseData?.teaser_image}
                ratio={[175, 100]}
                width={320}
                fallback
              />
              <Heading.h2>{advertiseData?.headline}</Heading.h2>
              <Text.p>{advertiseData?.teaser}</Text.p>
              <Button as={Link} bc="primary" href="/annonsera" padding="0 25px">
                Läs mer
              </Button>
            </Rhythm>

            <Rhythm between={[3, null, null, '1,5']}>
              <CloudinaryImage
                image={trStudioData?.teaser_image}
                ratio={[175, 100]}
                width={320}
                fallback
              />
              <Heading.h2>{trStudioData?.headline}</Heading.h2>
              <Text.p>{trStudioData?.teaser}</Text.p>
              <Button as={Link} bc="primary" href="/tr-studio" padding="0 25px">
                Läs mer
              </Button>
            </Rhythm>
          </Box>
        </Container>
      </Box>

      <CustomerServiceSection />
    </>
  );
};

export const getServerSideProps = wrapper.getServerSideProps(
  store => async context => {
    const { res } = context;

    store.dispatch(getContent.initiate(queryArgsAboutUs));
    store.dispatch(getContent.initiate(queryArgsNews));
    store.dispatch(getContent.initiate(queryArgsBrands));
    store.dispatch(getContent.initiate(queryArgsAdvertise));
    store.dispatch(getContent.initiate(queryArgsTrStudio));

    const queryArgs = {
      limit: 3,
    };

    store.dispatch(getContentList.initiate(queryArgs));

    await Promise.all(store.dispatch(getRunningQueriesThunk()));

    res.setHeader(
      'Cache-Control',
      'public, s-maxage=60, stale-while-revalidate=300',
    );

    return {
      props: {
        queryArgs,
      },
    };
  },
);

Home.getLayout = (page: React.ReactElement) => <SiteLayout>{page}</SiteLayout>;

export default Home;
