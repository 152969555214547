import { Container, Heading, Position, Rhythm, Text } from '@trmediaab/zebra';

import BrandGrid from '~/components/brand/BrandGrid';
import { useGetContentQuery } from '~/services/trais';

const queryArgs = 'varumarken';

const BrandsSection = () => {
  const { data } = useGetContentQuery(queryArgs);

  const { teaser, teaser_headline: teaserHeadline } = data || {};

  return (
    <Position.relative
      as="section"
      padding={['30px 0', null, null, '67px 0 54px']}
    >
      <Position.absolute
        top="0"
        left="0"
        width="100%"
        height={['70%', null, null, '40%']}
        bg="#000"
      />
      <Position.relative>
        <Container margin="auto" color="white">
          <Rhythm
            between={[2, null, null, 4]}
            mb={['20px', null, null, '55px']}
          >
            <Heading.h1 as="h2">{teaserHeadline}</Heading.h1>
            <Text.p
              maxWidth={['100%', null, null, '50%']}
              fontSize={['18px', null, null, '24px']}
            >
              {teaser}
            </Text.p>
          </Rhythm>
        </Container>

        <Container margin="auto">
          <BrandGrid scrollableOnMobile />
        </Container>
      </Position.relative>
    </Position.relative>
  );
};

export default BrandsSection;
