import { Box, FlexBox, Heading, Position, Text } from '@trmediaab/zebra';
import type { Brand } from '@trmediaab/zebra-utils';

import ClampedText from '~/components/ClampedText';
import CloudinaryImage from '~/components/CloudinaryImage';
import ExternalLink from '~/components/ExternalLink';
import External from '~/icons/External';
import type { ArchiveImage } from '~/types/article';

const makeArchiveImageFromId = (id: string): ArchiveImage => ({
  cdn_id: id,
  photographer: null,
  focus_point: null,
});

const BrandTile = (props: Brand) => {
  const { name, text, href, iconId = '', tileId = '' } = props;
  const iconImage = makeArchiveImageFromId(iconId);
  const tileImage = makeArchiveImageFromId(tileId);
  return (
    <ExternalLink
      href={href}
      overflow="hidden"
      flex="1 0 265px"
      display="flex"
      flexDirection="column"
      bg="greys.0"
      textDecoration="none"
      sx={{ position: 'relative', fontSize: [1, 2] }}
    >
      <CloudinaryImage
        image={tileImage}
        ratio={[175, 100]}
        width={320}
        fallback
      />
      <Position.absolute
        top="0"
        right="20px"
        width="60px"
        height="60px"
        p="13px"
        backgroundColor="rgba(255, 255, 255, 0.7)"
      >
        <CloudinaryImage image={iconImage} ratio={[1, 1]} width={34} fallback />
      </Position.absolute>
      <Box px="3" pt={[3, null, '3,5']} pb="2,5" flex="1">
        <ClampedText lines={4}>
          <Text color="black">
            <Heading.h3>{name}</Heading.h3>
            <Text.p mt="2,5">{text}</Text.p>
          </Text>
        </ClampedText>
      </Box>
      <Text ml="3" mb="3,5" fontWeight="medium" textDecoration="underline">
        <FlexBox alignItems="center">
          <External mr={[2, null, '2,5']} mt="1px" /> Till {name}
        </FlexBox>
      </Text>
    </ExternalLink>
  );
};

export default BrandTile;
